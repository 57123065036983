import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Expander, InfoIcon, WarningIcon, Tabs, List, Image } from '../components';
import { statUserCurl, statUserJava, statUserJavascript, statUserNode, statUserPerl, statUserPython, statUserPhp, statUserRuby, statUserGo, statUserC, statUserVB, statUserGroovy, statUserObjectiveC, statUserSwift } from 'requestExamples/user-accounts';
export const endpoints = [{
  param: 'GET',
  value: '/auto/usr/{email}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "getting-account-information"
    }}>{`Getting account information`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault allows you to get details about registered users; more specifically they let you retrieve their
			status regarding <a href="#authorized">authorization</a> and <a href="#activated">activation</a>.<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			Remember you'll need to use the <strong>client token</strong> for every getting the account information. Also,
			the info you add while the user is logged in will be wiped out once the user signs out of the account.
			<br />
			<br />
			<WarningIcon mdxType="WarningIcon" />
			The basic access authorization header value for this request needs to be:
			<code>clientId + ':' + clientToken</code>
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "authorized"
    }}>{`Authorized`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`The authorization property is related to the ability of your client to perform operations as the user being retrieved.`}</p>
    <InfoIcon mdxType="InfoIcon" />
If the user is activated but not authorized, the "authorization_uri" parameter will contain the URL that the user needs to
visit in order to authorize your application. This "authorization_uri" will show on the body of the response only if not authorized:
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "authorized": false,
        "activated": true,
        "authorization_uri": "https://my.smartvault.com/users/secure/IntegratedApplications.aspx?email=newAccountEmail&c=JNqR55qTh0Ov8T2ahA5Z1g",
        "id": "User:QVqGsFoKkU-OAx2KownMFw"
    }
}
`}</code></pre>
    <p>{`After clicking on the link, the user will be prompted to allow the request:`}</p>
    <Image src={'/user-accounts-authorization.png'} alt="Authorization prompt" title="Authorization prompt" width={25} mdxType="Image" />
    <br />
    <br />
    <p>{`Once you've clicked "Allow", the client id is now listed under "My Apps" and if you execute the request again, the "authorized" param will be set to "true".`}</p>
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <InfoIcon mdxType="InfoIcon" />
You can optionally add a "redirect_uri" parameter to this "authorization_uri" in order to return the user to where they were
in your workflow.
    <br />
    <br />
Example of an authorization URL, with redirect_uri added to the end:
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`https://my.smartvault.com/secure/users/IntegratedApplications.aspx?c=cCLwi4atPkiPfJhTmof-bg&redirect_uri=https://www.myawesomeapp.com%3Fstate%3Dmagicalstateinfo
`}</code></pre>
    <WarningIcon mdxType="WarningIcon" />
Ensure that you encode your URL properly if you use this feature.
    <br />
    <br />
    <br />
    <p>{`If any error happens while using the redirection, three additional parameters will be added to the URL when the user is redirected back to your application:`}</p>
    <br />
    <List inline values={[{
      title: 'error_reason',
      value: 'An integer based error value.'
    }, {
      title: 'error',
      value: 'A textual representation of the error.'
    }, {
      title: 'error_description',
      value: 'A more detailed description of the error.'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <h2 {...{
      "id": "activated"
    }}>{`Activated`}</h2>
    <hr style={{
      "width": "50%"
    }} />
    <p>{`Activation is a technique SmartVault uses to verify the end user has possession of the email account used for login purposes.`}<br /></p>
    <InfoIcon mdxType="InfoIcon" />
The "activated" property of the response indicates whether or not the user has been activated in SmartVault.
    <br />
    <InfoIcon mdxType="InfoIcon" />A user that has not been activated cannot log into SmartVault directly or indirectly (via your client). You'll
need to led the user to activate their account before being able to make the integration works. You can check more information
about this on the [Authorized section](#authorized) above.
    <br />
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: statUserCurl
    }, {
      tabTitle: 'Java',
      tabInfo: statUserJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: statUserJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: statUserNode
    }, {
      tabTitle: 'Perl',
      tabInfo: statUserPerl
    }, {
      tabTitle: 'Python',
      tabInfo: statUserPython
    }, {
      tabTitle: 'PHP',
      tabInfo: statUserPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: statUserRuby
    }, {
      tabTitle: 'Go',
      tabInfo: statUserGo
    }, {
      tabTitle: 'C#',
      tabInfo: statUserC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: statUserVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: statUserGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: statUserObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: statUserSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns whether or not the account its activated and / or authorized as well as the account id. You can get more information about what authorized and activated means at the beggining of this page.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "authorized": true,
        "activated": true,
        "id": "User:h98BbYudpEe_O3UaiuDCBA"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the email doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "c1cf4396-c7d7-4d24-ae27-7f33438d60ba",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      